<template>
  <div id="app">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body{
    background: rgb(21,3,40);
}
h1{
    background-image: linear-gradient(to bottom, #9533CB, #1C1D54);
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.1;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.container{
overflow: auto;
padding: 15px;
width: 80vw;
height: 70vh;
background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(165, 239, 255, 0.2) 15%, rgba(110, 191, 244, 0.0447917) 77.08%, rgba(45, 117, 184, 0) 100%) 25%;
background-blend-mode: overlay, normal;
filter: drop-shadow(15px 15px 4px rgba(0, 0, 0, 0.25)) drop-shadow(2px 16px 19px rgba(0, 0, 0, 0.09));
backdrop-filter: blur(80px);
border-radius: 8px;
}

p, h2, h3, li, ul, label{
    list-style: none;
    color: #fff;
}
span{
    background-image: linear-gradient(to bottom, #9533CB, #1C1D54);
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.1;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


</style>
