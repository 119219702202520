<template>
<body>
    <!-- Option 1: Bootstrap Bundle with Popper -->
    
    <header>
        <div class="d-flex justify-content-center">
          <img alt="Night logo" src="../assets/logo.png">
        </div>
        <div class="d-flex justify-content-center p-3">
          <h1> Suporte NightApp</h1>
        </div>
    </header>
        <div class="container text-center">
          
          <div class="form-group">
              <h3 class="mb-2">Como podemos te ajudar?</h3>

            <div class="form-group d-flex justify-content-center">
              <div class="col-10 col-md-6">
                            <label > Nome: </label>
                            <input type="text"  name="nome" class="form-control " placeholder="Digite seu nome..." required="" v-model="suporte.name">    
                        </div>
                    </div>
                    <div class="form-group d-flex justify-content-center">
                        <div class="col-10 col-md-6">
                            <label > E-mail: </label>
                            <input type="email"  name="email" class="form-control " placeholder="Digite seu e-mail...." required="" v-model="suporte.email">    
                        </div>
                    </div>
                    <div class="form-group d-flex justify-content-center">
                        <div class="col-10 col-md-6">
                            <label for="exampleFormControlSelect1">Assunto: </label>
                                <select class="form-control" id="exampleFormControlSelect1" name="assunto" v-model="suporte.matters">
                                <option value="ProblemasApp">Problemas no app</option>
                                <option value="Duvidas">Dúvidas sobre a plataforma</option>
                                <option value="Querserpromoter">Como ser um promoter?</option>
                                <option value="Quercadastrarevento">Cadastrar meus eventos</option>
                                <option value="QuerserDev">Quero ser dev na NightApp</option>
                                </select>
                        </div>
                    </div>
                    <div class="form-group d-flex justify-content-center">
                        <div class="col-10 col-md-6">
                            <label > Mensagem: </label>
                            <textarea type="textarea" name="mensagem" class="form-control "  required="" v-model="suporte.message"> </textarea>   
                        </div>
                    </div>
                    <div class="form-group d-flex justify-content-center mb-2">
                        <div class="col-10 col-md-6">
                            <label > Telefone: </label>
                            <input v-mask="maskTel()" type="tel" class="form-control" v-model="suporte.phone"/>
                        </div>
                    </div>
                </div>
                <div class="alert alert-success" role="alert mt-2" v-if="sucess == true">
                Solicitação de suporte enviada com sucesso!
              </div>
                <button  class="btn btn-primary mt-2" @click="save() " >Enviar</button>
        </div>  
</body>
</template>

<script>
import { baseApiUrl, showError, showSucess } from '@/global'
import axios from 'axios'

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data(){
    return{
      sucess: false,
      suporte:{
        name: "",       // required || NOME
        email: "",        // required || E-MAIL
        matters: "",       // required || ASSUNTO
        message: "",       // required || MENSAGEM
        phone:"",         // required || TELEFONE
        ative: true 
      }
    }
  },
  methods:{
    teste(){
      console.log(this.suporte)
    },
    save(){
            axios.post(`${baseApiUrl}/support`, this.suporte)
            .then((res) => {
                if(res.status === 204){
                    this.sucess = true
                    showSucess(res)
                }
            })
            .catch((error) => {
                showError(error)
            });
        },
    maskTel() {
      if ( this.suporte.phone.length == 15 ){
       return this.suporte.phone.length  == '(##) #####-####' 
      } else {
        return '(##) ####-####'
      }
    },

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import'~bootstrap/dist/css/bootstrap.css';
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
